import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from "axios";
//import axios, { AxiosHeaders } from "axios";
import { useState } from "react";

const App = () => {

  const [post, setPost] = useState({
    mode: 1,
    eventID: 14,
    startNumber: 96,
    onTrack: 1 
  })

  const [postResult, setPostResult] = useState({
    eventID: 0,
    memberStartNumber: 0,
    lap: 0,
    penaltySec: 0,
    resultType: "x"
  })

  const [penaltySec, setPenaltySec] = useState(0)

  const handleInput = (event) => { setPost({...post, [event.target.name]: event.target.value}) }
  const handleInputResult = (event) => { setPostResult({...postResult, [event.target.name]: event.target.value}) }

  function handleSubmit(event) {
    event.preventDefault()
    //console.log(post)
    axios.post("https://api.pjtk.cz/ROT/set", post)
    .then(response => console.log(response))
    .catch(err => console.log(err))
  }
  
  function handleSubmitResult(event) {
    event.preventDefault()
//    postResult.eventID = event.target.eventID.value
    postResult.eventID = post.eventID
    postResult.memberStartNumber = post.startNumber
    postResult.lap = event.target.lap.value
    postResult.penaltySec = event.target.penaltySec.value
    postResult.resultType = event.target.resultType.value

    console.log(postResult)
    axios.post("https://api.pjtk.cz/result/decision", postResult)
    .then(response => console.log(response))
    .catch(err => console.log(err))
  }

  
  return (
    <div className='d-flex align-items-center justify-content-center vh-100 w-100'>
      <div className="w-50 text-center">

        <form onSubmit={handleSubmit}>
          mode: <input type="text" size={1} onChange={handleInput} name="mode" id="" /> 
          eventID: <input type="text" size={1} onChange={handleInput} name="eventID" id="" /> 
          onTrack: <input type="text" size={1} onChange={handleInput} name="onTrack" id="" /><br /><br />
          startNumber: <br /> <input type="text" className="start-number" onChange={handleInput} name="startNumber" id=""  />
          <br />
          <button className="btn btn-primary btn-rider-fontsize">Jezdec na trati</button>
        </form>

        <hr />

        <form onSubmit={handleSubmitResult}>
          Kolo: <input type="text" size={1} onChange={handleInputResult} name="lap" id="" /><br /><br />
          Penalizace a hodnocení:<br />
          <input type="text" className="penalty" onChange={handleInputResult} name="penaltySec" id="" /><select name="resultType" className="resultType" onChange={handleInputResult} id="">
                        <option value="valid">OK</option>
                        <option value="DSQ">DSQ</option>
                        <option value="DNS">DNS</option>
                        <option value="DNF">DNF</option>
                      </select>
          
          <br /> <button className="btn btn-primary btn-result-fontsize">Uložit hodnocení</button>
        </form>

      </div>
    </div>
  )

}

export default App

// https://jsonplaceholder.typicode.com/posts